<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tab_wrap">
        <div class="tab_list">
          <ul>
            <li
              :class="{ active: tabIndex == 0 }"
              @click.prevent="selectTabIndex(0)"
            >
              생산 출고
            </li>
            <li
              :class="{ active: tabIndex == 1 }"
              @click.prevent="selectTabIndex(1)"
            >
              반품 출고
            </li>
          </ul>
        </div>
        <div class="tab_cont">
          <div class="tab01" v-if="tabIndex === 0">
            <div class="tab_opt">
              <div class="input_search">
                <input type="text" placeholder="작업지시서 검색" />
                <button type="button" @click="showLotSearch = true">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="mes_tbl_wrap" v-if="target_lot != null">
              <table class="mes_tbl">
                <thead>
                  <tr>
                    <th>작업지시일</th>
                    <th>거래처</th>
                    <th>제품명</th>
                    <th>지시수량</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ target_lot.input_date }}</td>
                    <td>
                      {{
                        target_lot.company_id == null
                          ? `자체생산`
                          : findInfoFromId(companys, target_lot.company_id).name
                      }}
                    </td>
                    <td>
                      {{ findInfoFromId(products, target_lot.product_id).name }}
                    </td>
                    <td>{{ target_lot.quantity }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h6 class="select_title">출고 원자재 선택</h6>
            <div class="mes_tbl_wrap" v-if="target_lot != null">
              <table class="mes_tbl">
                <thead>
                  <tr>
                    <th>원자재명</th>
                    <th>규격</th>
                    <th>투입수량(단위)</th>
                    <th>입고일자</th>
                    <th>식별코드</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(tracking, index) in tracking_resource"
                    :key="tracking.id"
                    @click="selectLotRow(index)"
                    :class="{ active: selectedLotIndex == index }"
                  >
                    <td>
                      {{ findInfoFromId(materials, tracking.material_id).name }}
                    </td>
                    <td>
                      {{
                        findInfoFromId(materials, tracking.material_id).standard
                      }}
                    </td>
                    <td>
                      <b>{{
                        tracking.material_id != null
                          ? `${$makeComma(
                              $decimalDiv(
                                tracking.quantity,
                                getUnitConversion(tracking.material_id),
                              ),
                            )}`
                          : `${$makeComma(tracking.quantity)}`
                      }}</b
                      >{{
                        ` (${findInfoFromId(units, tracking.unit_id).name})`
                      }}
                    </td>
                    <td>
                      {{ tracking.create_time | formatDateNoHours }}
                    </td>
                    <td>
                      {{ tracking.ident_value }}
                      <button class="btn_tbl" @click="trackingStatus(tracking)">
                        현황 추적
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tab02" v-else>
            <div class="tab_opt">
              <div class="input_search">
                <input
                  type="text"
                  placeholder="원자재명 검색"
                  @input="typingMaterial($event)"
                  :value="searchText2"
                />
                <button type="button"><i class="fa fa-search"></i></button>
              </div>
              <div class="input_text">
                <label>반환액 처리</label>
                <my-local-selectric
                  :id="'total_selectric'"
                  :watch="checkTotalSelect"
                  :options="total_options"
                  @changeValue="changeSelectric($event)"
                >
                </my-local-selectric>
              </div>
            </div>
            <h6 class="select_title">반품 원자재 선택</h6>
            <h6 class="views">조회수 : {{ returnableList.length }}건</h6>
            <div class="mes_tbl_wrap">
              <table class="mes_tbl">
                <thead>
                  <tr>
                    <th>원자재명</th>
                    <th>창고명</th>
                    <th>세부위치</th>
                    <th>규격</th>
                    <th>재고량(단위)</th>
                    <th>단가</th>
                    <th>지출액</th>
                    <th>입고일</th>
                    <th>반환단가 입력여부</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in returnableList"
                    :key="item.id"
                    @click="selectRow(index)"
                    :class="{ active: selectedIndex == index }"
                  >
                    <td>
                      {{ findInfoFromId(materials, item.material_id).name }}
                    </td>
                    <td>{{ findInfoFromId(stores, item.store_id).name }}</td>
                    <td>
                      {{ findInfoFromId(locations, item.store_id).name }}
                    </td>
                    <td>
                      {{ findInfoFromId(materials, item.material_id).standard }}
                    </td>
                    <td>
                      {{ item.quantity | makeComma
                      }}{{ `(${findInfoFromId(units, item.unit_id).name})` }}
                    </td>
                    <td>&#8361; {{ item.cost | makeComma }}</td>
                    <td>
                      &#8361;
                      {{
                        mx_makeComma(
                          calSupplyTax(
                            item.vat_id,
                            item.tax_id,
                            $makeNumber(item.quantity),
                            $makeNumber(item.cost),
                          ).total,
                        )
                      }}
                    </td>
                    <td>{{ item.create_time | formatDateNoHours }}</td>
                    <td>
                      {{ item.cost > 0 ? `Y` : `N` }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="head">
        <h5>세부정보</h5>
      </div>
      <div class="form" @submit.prevent>
        <form v-if="tabIndex === 0">
          <div class="input_text">
            <label>입고일</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="
                managementLotData == null
                  ? ''
                  : managementLotData.create_time.substr(0, 10)
              "
              disabled
            />
          </div>
          <div class="input_text">
            <label>식별코드</label>
            <input
              type="text"
              placeholder="식별코드"
              :value="
                managementLotData == null ? '' : managementLotData.ident_value
              "
              disabled
            />
          </div>
          <div class="input_text">
            <label>원자재명</label>
            <input
              type="text"
              placeholder="원자재명"
              :value="
                managementLotData == null
                  ? ''
                  : findInfoFromId(materials, managementLotData.material_id)
                      .name
              "
              disabled
            />
          </div>
          <div class="input_text">
            <label>구매처명</label>
            <input
              type="text"
              placeholder="구매처명"
              :value="
                target_lot != undefined
                  ? target_lot.company_id == null
                    ? `자체생산`
                    : findInfoFromId(companys, target_lot.company_id).name
                  : ''
              "
              disabled
            />
          </div>
          <div class="inputs">
            <div class="input_text">
              <label>수량</label>
              <input
                type="text"
                placeholder="수량"
                :value="
                  managementLotData == null
                    ? ''
                    : `${$makeComma(
                        $decimalDiv(
                          managementLotData.quantity,
                          getUnitConversion(managementLotData.material_id),
                        ),
                      )}`
                "
                @input="$inputNumber($event, managementLotData, 'quantity')"
                :readonly="managementLotData == null"
                disabled
              />
            </div>
            <div class="input_text">
              <label>단위</label>
              <input
                type="text"
                placeholder="단위"
                :value="
                  managementLotData == null
                    ? ''
                    : findInfoFromId(units, managementLotData.unit_id).name
                "
                disabled
              />
            </div>
          </div>
          <div class="input_text">
            <label>출고창고</label>
            <my-local-selectric
              :id="'storage_selectric'"
              :watch="selected_store_type"
              :options="store_type"
              @changeValue="changeStoreSelectric($event)"
            >
            </my-local-selectric>
          </div>
          <div class="btn_wrap">
            <button
              type="button"
              class="btn_sub2"
              :disabled="selectedLotIndex == -1"
              @click="saveData"
            >
              <!-- ||
                  $makeNumber(managementLotData.quantity) == 0 -->
              원자재 생산 출고
            </button>
          </div>
        </form>
        <form v-else>
          <div class="input_text">
            <label>구매처명</label>
            <input
              type="text"
              placeholder="구매처명"
              readonly
              :value="
                managementData == null
                  ? ''
                  : findInfoFromId(companys, managementData.company_id).name
              "
            />
          </div>
          <div class="input_text">
            <label>원자재명</label>
            <input
              type="text"
              placeholder="원자재명"
              readonly
              :value="
                managementData == null
                  ? ''
                  : findInfoFromId(materials, managementData.material_id).name
              "
            />
          </div>
          <div class="input_text">
            <label>규격</label>
            <input
              type="text"
              placeholder="규격"
              readonly
              :value="
                managementData == null
                  ? ''
                  : findInfoFromId(materials, managementData.material_id)
                      .standard
              "
            />
          </div>
          <div class="input_text">
            <label>창고위치</label>
            <input
              type="text"
              placeholder="창고위치"
              readonly
              :value="
                managementData == null
                  ? ''
                  : findInfoFromId(stores, managementData.store_id).name
              "
            />
          </div>
          <div class="input_text">
            <label>세부위치</label>
            <input
              type="text"
              placeholder="세부위치"
              readonly
              :value="
                managementData == null
                  ? ''
                  : findInfoFromId(locations, managementData.store_id).name
              "
            />
          </div>
          <div class="input_text return_amount_input">
            <label>반품수량</label>
            <input
              type="text"
              placeholder="EA"
              :value="
                managementData == null
                  ? ''
                  : $makeComma(managementData.quantity)
              "
              inputmode="decimal"
              @input="$inputNumber($event, managementData, 'quantity')"
              :readonly="managementData == null"
            />
            <label>{{
              managementData == null
                ? ''
                : findInfoFromId(units, managementData.unit_id).name
            }}</label>
          </div>
          <div class="input_text">
            <label>반품구분</label>
            <my-selectric
              v-if="managementData != null"
              :id="'return_type_selectric'"
              :watch="managementData.material_in_out_type_id"
              :options="returnOptions"
              :commit="'setManagementDataReturnTypeToMaterialReturn'"
            >
            </my-selectric>
          </div>
          <div class="permission_input" v-if="inputPermission">
            <div class="input_text">
              <label>반환단가</label
              ><input
                type="text"
                placeholder="반환단가"
                readonly
                :value="
                  managementData == null
                    ? ''
                    : managementData.material_in_out_type_id == returnOutTypeId
                    ? '₩ ' + $makeComma(managementData.cost)
                    : '₩ 0'
                "
              />
            </div>
            <div class="input_text">
              <label>공급가액</label
              ><input
                type="text"
                placeholder="공급가"
                readonly
                :value="
                  managementData == null
                    ? ''
                    : managementData.material_in_out_type_id == returnOutTypeId
                    ? '₩ ' +
                      mx_makeComma(
                        calSupplyTax(
                          managementData.vat_id,
                          managementData.tax_id,
                          $makeNumber(managementData.quantity),
                          $makeNumber(managementData.cost),
                        ).supply,
                      )
                    : 0
                "
              />
            </div>
            <div class="input_text">
              <label>부가세액</label
              ><input
                type="text"
                placeholder="부가세"
                readonly
                :value="
                  managementData == null
                    ? ''
                    : managementData.material_in_out_type_id == returnOutTypeId
                    ? '₩ ' +
                      mx_makeComma(
                        calSupplyTax(
                          managementData.vat_id,
                          managementData.tax_id,
                          $makeNumber(managementData.quantity),
                          $makeNumber(managementData.cost),
                        ).tax,
                      )
                    : 0
                "
              />
            </div>
            <div class="input_text">
              <label>반환액</label
              ><input
                type="text"
                placeholder="반환액"
                readonly
                :value="
                  managementData == null
                    ? ''
                    : managementData.material_in_out_type_id == returnOutTypeId
                    ? '₩ ' +
                      mx_makeComma(
                        calSupplyTax(
                          managementData.vat_id,
                          managementData.tax_id,
                          $makeNumber(managementData.quantity),
                          $makeNumber(managementData.cost),
                        ).total,
                      )
                    : 0
                "
              />
            </div>
            <div class="input_text">
              <label>반환사유</label
              ><input
                type="text"
                :readonly="managementData == null"
                :value="managementData == null ? '' : managementData.detail"
                @input="
                  $event => {
                    managementData.detail = $event.target.value;
                  }
                "
              />
            </div>
          </div>
          <div class="btn_wrap">
            <button
              type="button"
              class="btn_sub2"
              @click="submitForm()"
              v-if="isPermissionOn"
              :disabled="
                selectedIndex == -1 || $makeNumber(managementData.quantity) == 0
              "
            >
              원자재 반품 출고
            </button>
          </div>
        </form>
      </div>
    </div>
    <lot-search
      v-if="showLotSearch"
      @onclose="showLotSearch = false"
      @onselect="selectLot($event)"
    ></lot-search>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import FAVORITE_MIXIN from '@/mixins/favorite';
import MODAL_MIXIN from '@/mixins/modal';
import SPINNER_MIXIN from '@/mixins/spinner';
import MATERIAL_PRICE_MIXIN from '@/mixins/material_price';
import MySelectric from '@/layouts/components/MySelectric';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import { yyyymmdd } from '@/utils/func';
import LotSearch from '@/layouts/components/search-popup/LotSearch';
import routes from '@/routes/routes';
export default {
  mixins: [
    FETCH_MIXIN,
    MODAL_MIXIN,
    SPINNER_MIXIN,
    MATERIAL_PRICE_MIXIN,
    FAVORITE_MIXIN,
  ],
  components: {
    MySelectric,
    MyLocalSelectric,
    LotSearch,
  },
  data() {
    return {
      tabIndex: 0,
      selected_store_type: null,
      checkTotalSelect: null,
      showLotSearch: false,
      total_options: [
        {
          label: '선택',
          value: null,
        },
        {
          label: '처리',
          value: 1,
        },
        {
          label: '미처리',
          value: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      target_lot: 'getTargetLotFromProduction',
      tracking_resource: 'getLotTrackingResource',
      unitConversions: 'getUnitConversion',
      userId: 'getUserId',
      users: 'getUsersTempFromUserPage',
      returnableListSrc: 'getReturnableListFromMaterialReturnAll',
      returnTypes: 'getReturnTypes',
      returnOutTypeId: 'getReturnOutTypeId',
      returnOptions: 'getReturnTypesForSelectric',
      companys: 'getCompany',
      products: 'getProduct',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      stores: 'getStores',
      store_type: 'getStoresForSelectric',
      selectedIndex: 'getSelectedIndexFromMaterialReturn',
      selectedLotIndex: 'getSelectedIndexFromLotTracking',
      managementData: 'getManagementDataFromMaterialReturn',
      managementLotData: 'getManagementLotDataFromLotTracking',
      searchText2: 'getSearchTextFromMaterialReturnOut',
    }),
    returnableList() {
      if (this.returnableListSrc.length < 1 || this.materials.length < 1) {
        return [];
      } else {
        const Hangul = require('hangul-js');
        return this.lodash
          .clonedeep(this.returnableListSrc)
          .filter(
            x =>
              this.findInfoFromId(this.materials, x.material_id).name.includes(
                this.searchText2,
              ) ||
              Hangul.d(
                this.findInfoFromId(this.materials, x.material_id).name,
                true,
              )
                .map(x => x[0])
                .join('')
                .includes(this.searchText2),
          )
          .filter(x =>
            this.checkTotalSelect == null
              ? x
              : this.checkTotalSelect == 0
              ? this.calSupplyTax(
                  x.vat_id,
                  x.tax_id,
                  this.$makeNumber(x.quantity),
                  this.$makeNumber(x.cost),
                ).total == 0
              : this.calSupplyTax(
                  x.vat_id,
                  x.tax_id,
                  this.$makeNumber(x.quantity),
                  this.$makeNumber(x.cost),
                ).total != 0,
          )
          .sort(
            (a, b) =>
              new Date(b.create_time) - new Date(a.create_time) || b.id - a.id,
          );
      }
    },
    locations() {
      if (this.stores.length < 1) {
        return [];
      } else {
        return this.stores.map(x => x.locations).reduce((a, b) => a.concat(b));
      }
    },
    checkValid() {
      if (this.managementData == null) {
        return false;
      } else {
        if (
          Number(
            Number(
              String(this.managementData.quantity).replace(/,/gi, ''),
            ).toFixed(6),
          ) >
          Number(
            Number(
              String(this.managementData.curr_stock).replace(/,/gi, ''),
            ).toFixed(6),
          )
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    checkTotalCostValid() {
      let calTotalCost;

      if (this.managementData != null) {
        if (
          this.managementData.material_in_out_type_id == this.returnOutTypeId
        ) {
          calTotalCost = this.calSupplyTax(
            this.managementData.vat_id,
            this.managementData.tax_id,
            this.$makeNumber(this.managementData.quantity),
            this.$makeNumber(this.managementData.cost),
          ).total;
        } else {
          calTotalCost = 0;
        }
        if (calTotalCost <= 2147483647) {
          return true;
        } else {
          return false;
        }
      } else return true;
    },
    inputPermission() {
      const userRoleTypeId = this.users.find(x => x.account == this.userId)
        .user_role_type_id;
      return userRoleTypeId === 1 ? true : false;
    },
  },
  methods: {
    getUnitConversion(material_id) {
      const target_mat = this.findInfoFromId(this.materials, material_id);
      const foundItem = this.unitConversions.find(
        x =>
          x.in_unit_id == target_mat.incoming_unit_id &&
          x.out_unit_id == target_mat.using_unit_id,
      );
      if (foundItem != null) {
        return foundItem.cvr_ratio;
      } else {
        return 1;
      }
    },
    async trackingStatus(item) {
      const goingPage = routes[0].children.find(
        x => x.path == `/${item.resource_gubun}/status`,
      );
      if (goingPage != undefined) {
        goingPage.meta.target = this.lodash.clonedeep(item);
        await this.$router.push(`/${item.resource_gubun}/status`);
      }
    },
    selectTabIndex(index) {
      this.tabIndex = index;
      this.selectRow(-1);
      this.selectLotRow(-1);
    },
    typingMaterial(e) {
      this.$store.commit('setSearchTextToMaterialReturnOut', e.target.value);
      this.$store.commit('setManagementDataToMaterialReturn', null);
      this.$store.commit('setSelectedIndexToMaterialReturn', -1);
    },
    changeSelectric(type) {
      this.checkTotalSelect = type;
      this.selectRow(-1);
    },
    changeStoreSelectric(type) {
      this.selected_store_type = type;
      Object.assign(this.managementLotData, { store_id: type });
    },
    async selectLot(arg) {
      this.$store.commit('setTargetLotToProduction', null);
      this.FETCH_LOT_PRODUCTION(arg.id);
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_LOT_TRACKING_RESOURCE',
        arg.id,
        '투입',
      );
      this.showLotSearch = false;
    },
    async FETCH_LOT_PRODUCTION(lot_id) {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_LOT_PRODUCTION', lot_id)
        .then(response => {
          console.log('패치 라트 프로덕션 ', response.data);
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('로드 실패', '삭제된 작업입니다.');
          this.$store.commit('setTargetLotToProduction', null);
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    submitForm() {
      if (this.checkValid && this.checkTotalCostValid) {
        const payload = this.lodash.clonedeep(this.managementData);
        payload.standard = this.findInfoFromId(
          this.materials,
          payload.material_id,
        ).standard;

        let date = new Date();
        payload.input_date = yyyymmdd(date);
        // console.log(payload);
        console.log(
          'payload.material_in_out_type_id ',
          payload.material_in_out_type_id,
        );
        console.log('this.getReturnOutTypeId', this.returnOutTypeId);
        if (payload.material_in_out_type_id == this.returnOutTypeId) {
          payload.cost = this.$makeNumber(payload.cost);
          payload.quantity = this.$makeNumber(payload.quantity);
          payload.supply_value = this.calSupplyTax(
            payload.vat_id,
            payload.tax_id,
            payload.quantity,
            payload.cost,
          ).supply;
          payload.tax = this.calSupplyTax(
            payload.vat_id,
            payload.tax_id,
            payload.quantity,
            payload.cost,
          ).tax;
          payload.total_cost = this.calSupplyTax(
            payload.vat_id,
            payload.tax_id,
            payload.quantity,
            payload.cost,
          ).total;
          console.log(payload);
        } else {
          payload.cost = 0;
          payload.supply_value = 0;
          payload.tax = 0;
          payload.total_cost = 0;
          console.log(payload);
        }
        this.showSpinner();
        this.$store
          .dispatch('INSERT_MATERIAL_RETURN', payload)
          .then(async () => {
            let name = this.findInfoFromId(
              this.returnTypes,
              payload.material_in_out_type_id,
            ).name;
            this.openOneButtonModal(name + '성공', name + ' 처리하였습니다.');
            await this.FETCH('FETCH_RETURNABLE_MATERIAL_LIST', '구매 기록');
            this.selectRow(-1);
          })
          .catch(() => {
            this.openOneButtonModal(
              '반품실패',
              '반품 등록 중 오류가 발생하였습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else if (!this.checkValid) {
        this.openOneButtonModal('반품불가', '현재고 이상 반품은 불가능합니다');
      } else if (!this.checkTotalCostValid) {
        this.openOneButtonModal(
          '반환액 초과',
          '1회 반환 최대 금액은<br />20억 이상 반환할 수 없습니다.',
        );
      }
    },
    selectRow(index) {
      this.$store.commit('setSelectedIndexToMaterialReturn', index);
      if (index == -1) {
        this.$store.commit('setManagementDataToMaterialReturn', null);
      } else {
        let manageData = this.lodash.clonedeep(this.returnableList[index]);
        manageData['material_in_out_type_id'] = this.lodash.clonedeep(
          this.returnOutTypeId,
        );
        manageData['detail'] = '';
        manageData['curr_stock'] = this.lodash.clonedeep(manageData.quantity);
        this.$store.commit('setManagementDataToMaterialReturn', manageData);
      }
    },

    selectLotRow(index) {
      this.$store.commit('setSelectedIndexToLotTracking', index);
      if (index == -1) {
        this.$store.commit('setManagementLotDataToLotTracking', null);
      } else {
        // let lotTracking = this.lodash.clonedeep(this.tracking_resource[index]);
        this.$store.commit(
          'setManagementLotDataToLotTracking',
          this.lodash.clonedeep(this.tracking_resource[index]),
        );
        console.log(this.tracking_resource[index]);
      }
    },
    saveData() {
      console.log(this.managementLotData);
    },
  },
  async created() {
    if (this.returnTypes.length < 1) {
      await this.FETCH('FETCH_MATERIAL_IN_OUT_TYPE');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT');
    }
    if (this.stores.length < 1) {
      await this.FETCH('FETCH_STORE', '창고 리스트');
    }
    if (this.unitConversions.length < 1) {
      await this.FETCH('FETCH_UNIT_CONVERSION', '단위환산');
    }
    if (this.returnableListSrc.length < 1) {
      await this.FETCH('FETCH_RETURNABLE_MATERIAL_LIST', '구매기록');
    }
    if (this.store_type.length < 1) {
      await this.$store.commit('setStoreToOutputPage', this.stores);
    }

    if (this.target_lot != null) {
      await this.FETCH_NO_SPINNER(
        'FETCH_PRODUCT_OCCUPIED_STOCK',
        '제품 사용중 재고',
      );
      await this.FETCH_LOT_PRODUCTION(this.target_lot.id);
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_LOT_TRACKING_RESOURCE',
        this.target_lot.id,
        '투입',
      );
    }

    if (this.selectedIndex != -1) {
      const findIndex = this.returnableList.findIndex(
        x => x.id == this.managementData.id,
      );

      this.selectRow(findIndex);
    }
    // if (this.selectedLotIndex != -1) {
    //   const findLotIndex = this.tracking_resource.findIndex(
    //     x => x.id == this.managementLotData.id,
    //   );

    //   this.selectLotRow(findLotIndex);
    // }
  },
};
</script>

<style></style>
