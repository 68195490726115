<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="tbl_opt">
          <span>납품요청일</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="startDate"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="endDate"
                @change="setEndDate($event)"
              />
            </div>
          </div>
          <div class="input_search">
            <input v-model="prdName" type="text" placeholder="제품명 검색" />
            <button type="button"><i class="fa fa-search"></i></button>
          </div>
          <span>수금액 등록</span>
          <div>
            <my-local-selectric
              :id="'collection_amount_selectric'"
              :watch="collection_amount_type"
              :options="collection_amount_all"
              @changeValue="changeSelectric($event)"
            >
            </my-local-selectric>
          </div>
        </div>
      </div>
      <h6 class="views">
        조회수 : 00건
      </h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>제품명</th>
              <th>납품처</th>
              <th>Lot.No</th>
              <th>보관창고</th>
              <th>세부위치</th>
              <th>규격</th>
              <th>재고량(단위)</th>
              <th>단가</th>
              <th>매출액</th>
              <th>납품요청일</th>
              <th>수금액등록</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="prd in filteredTable" :key="prd.product_name">
              <td>{{ prd.product_name }}</td>
              <td>{{ prd.company_name }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="head">
        <h5>세부정보</h5>
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div class="input_text">
            <label>구매처명</label>
            <input type="text" placeholder="구매처명" />
          </div>
          <div class="input_text">
            <label>제품명</label>
            <input type="text" placeholder="제품명" />
          </div>
          <div class="input_text">
            <label>규격</label>
            <input type="text" placeholder="규격" />
          </div>
          <div class="input_text">
            <label>창고위치</label>
            <input type="text" placeholder="창고위치" />
          </div>
          <div class="input_text">
            <label>세부위치</label>
            <input type="text" placeholder="세부위치" />
          </div>
          <div class="input_text">
            <label>출고수량</label>
            <input type="text" placeholder="출고수량" />
          </div>
          <div class="permission_input" v-if="inputPermission">
            <div class="input_text">
              <label>공급가액</label>
              <input type="text" placeholder="공급가액" />
            </div>
            <div class="input_text">
              <label>부가세액</label>
              <input type="text" placeholder="부가세액" />
            </div>
            <div class="input_text">
              <label>할인액</label>
              <input type="text" placeholder="할인액" />
            </div>
            <div class="input_text">
              <label>매출액</label>
              <input type="text" placeholder="매출액" />
            </div>
            <div class="input_text">
              <label>수금액</label>
              <input type="text" placeholder="수금액" />
            </div>
            <div class="input_text">
              <label>수금구분</label>
              <div class="select_wrap">
                <div class="select">
                  <div class="label">선택</div>
                  <button type="button"></button>
                </div>
              </div>
            </div>
          </div>
          <div class="btn_wrap">
            <button type="button" class="btn_sub2">
              제품 생산 출고
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';

export default {
  components: { MyLocalSelectric },
  data() {
    return {
      collection_amount_all: [
        { label: '전체', value: null, detail: '' },
        { label: 'Y', value: 'Y', detail: '' },
        { label: 'N', value: 'N', detail: '' },
      ],
      testTable: [
        { product_name: '테스트제품1', company_name: '납품처1' },
        { product_name: '검색테스트제품2', company_name: '납품처2' },
      ],
      prdName: '',
    };
  },
  computed: {
    ...mapGetters({
      userId: 'getUserId',
      users: 'getUsersTempFromUserPage',
      startDate: 'getStartDateFromProductOutputPage',
      endDate: 'getEndDateFromProductOutputPage',
      collection_amount_type: 'getCollectionAmountTypeFromProductOutputPage',
    }),
    inputPermission() {
      const userRoleTypeId = this.users.find(x => x.account == this.userId)
        .user_role_type_id;
      return userRoleTypeId === 1 ? true : false;
    },
    filteredTable() {
      const Hangul = require('hangul-js');
      return this.testTable.filter(
        x =>
          x.product_name.includes(this.prdName) ||
          Hangul.d(x.product_name, true)
            .map(x => x[0])
            .join('')
            .includes(this.prdName),
      );
    },
  },
  methods: {
    async setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.endDate) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.endDate}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.startDate;
        } else {
          this.$store.commit('setStartDateToProductOutputPage', e.target.value);
          // 데이터 fetch 해야함
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setStartDateToProductOutputPage',
          yyyymmdd(new Date()),
        );
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.startDate) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.startDate}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.endDate;
        } else {
          this.$store.commit('setEndDateToProductOutputPage', e.target.value);
          // 데이터 fetch 해야함
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setEndDateToProductOutputPage',
          yyyymmdd(new Date()),
        );
      }
    },
    getAccount(id) {
      return this.users.filter(user => user.id == id)[0].account;
    },
    changeSelectric(type) {
      this.$store.commit('setCollectionAmountTypeToProductOutputPage', type);
      // 테이블 필터 해야함
    },
  },
  async created() {
    let date = new Date();
    if (this.startDate == '' || this.endDate == '') {
      date.setDate(date.getDate() + 1);
      this.$store.commit('setEndDateToProductOutputPage', yyyymmdd(date));
      date.setDate(date.getDate() - 13);
      this.$store.commit('setStartDateToProductOutputPage', yyyymmdd(date));
    }
  },
};
</script>

<style></style>
