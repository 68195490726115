<template>
  <div
    id="contents"
    class="storage_management"
    :class="{
      material_output: tabIndex == 0,
      product_output: tabIndex == 1,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <AsideSelectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToOutputManagementPage'"
          :options="asideLists"
        />
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            v-for="(asideList, index) in asideLists"
            :key="index"
            :class="{ active: tabIndex == index }"
          >
            <a href="#" @click.prevent="SetOpenTabIndex(index)">{{
              asideList.title
            }}</a>
          </li>
        </ul>
      </div>
      <MaterialOutputForm v-if="tabIndex == 0" />
      <ProductOutputForm v-if="tabIndex == 1" />
    </div>
  </div>
</template>

<script>
import MaterialOutputForm from '@/views/forms/Storage/MaterialOutputForm';
import ProductOutputForm from '@/views/forms/Storage/ProductOutputForm';

import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import { mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  data() {
    return {
      asideLists: [
        { title: '원자재 출고', name: 'material_output' },
        { title: '제품 출고', name: 'product_output' },
      ],
    };
  },
  components: {
    AsideSelectric,
    MaterialOutputForm,
    ProductOutputForm,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromOutputManagementPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToOutputManagementPage',
    }),
  },
  // async created() {
  //   if (this.$route.meta.menu_init == true) {
  //     this.$store.commit('InitOutputManagementPage');
  //     this.$route.meta.menu_init = false;
  //   }
  // },
};
</script>

<style></style>
